import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";


/* componente */
import Home from "./page/home";
import Navbar from "./components/navbar/navigation";
import Footer from "./components/footter/footer";
import Contacto from "./page/contacto";
import Servicios from "./page/servicios";
import Rack from "./page/racks";
import Productos from "./page/productos";
import Productoid from "./page/producto";
import Carro from "./components/otroscomponentes/carrito";
import { CarritoContextoProvider } from "./components/context/cotizar-final";
import { CarritoProvider } from "./components/context/carrito-cotizar";
import RackPage from "./page/rack";


const Layout = () => {
    const basename = process.env.BASENAME || "";

    return (
        <>
       <CarritoProvider>
        <CarritoContextoProvider>
         <BrowserRouter basename={basename}>
            <Navbar/>
         <Routes> 
         <Route element={<Home />} path="/" />
         <Route element={<Contacto />} path="/contacto" />
         <Route element={<Servicios />} path="/servicios" />
         <Route element={<Rack />} path="/Catalogo" />
         <Route element={<Productos />} path="/Productos" />
         <Route element={<Carro/>} path="/cart" />
          <Route
              path="/producto/:id"
              element={<Productoid />}
            /> 
             <Route
              path="/Rack/:id"
              element={<RackPage />}
            /> 
         </Routes>
         <Footer/>
         </BrowserRouter>
         </CarritoContextoProvider>
       </CarritoProvider>
        </>
    )
}


export default (Layout);