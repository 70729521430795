import { Grid } from "@mui/material";
import "../../style/home/home.css";
import React, { useState, useEffect } from "react";
import axios from 'axios';


export default function ServiciosHome() {
    const [servicios, setServicios] = useState([]);
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    useEffect(() => {
        // Realiza la solicitud a la API al cargar el componente
        axios.get(`${backendURL}/api/get/all/videos`)
          .then(response => {
            // Filtra los servicios que tienen tipo 'portada' en su array
            const portadaServicios = response.data.filter(videos => videos.tipo === 'servicios');
            setServicios(portadaServicios);
          })
          .catch(error => {
            console.error('Error fetching servicios:', error);
          });
      }, []);
    
    return (
        <>
        <section className="titulo-servicios-principal">
            <h1 className="titulo-principal-servicio">NUESTROS SERVICIOS</h1>
        </section>
        <Grid container  className="contenedor-servicios-home">
        {servicios.map(servicio => (
          <Grid key={servicio.id} xs={12} sm={6} md={6} lg={3} xl={3} className="servicios-home">
            <a href={servicio.url}>
              <div className="card-servicio-home">
                <div className="img-contenedor-servicio-home">
                  {servicio.imagen && (
                    <img  
                      src={`${backendURL}/${servicio.imagen}`}
                      alt={servicio.alt_seo || servicio.titulo}
                      className="contenedorservicioshome"
                    />
                  )}
                  <div className="info-servicio">
                    <h1 className="titulo-servicios-home">{servicio.titulo}</h1>
                    <h3 className='subtitulodos-servicio-home'>{servicio.descripcion}</h3>
                  </div>
                </div>
                <button className="button-servicios-home">{servicio.titulo}</button>
              </div>
            </a>
          </Grid>
        ))}
        </Grid>
        </>
    );
}
