
import "../../style/racks/racks.css";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaAngleLeft } from "react-icons/fa";
import { FaAngleRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import LoaderModal from "../otroscomponentes/louding";

/* imagenes */
import linea from "../../img/home/contactohome/lineas.png";

import { Grid } from "@mui/material";

export default function Racks() {
  const [racksData, setRacksData] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Nuevo estado para indicar si está cargando
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;
  const backendURL = process.env.REACT_APP_BACKEND_URL; 
  const [buttonsToShow, setButtonsToShow] = useState(3);

  useEffect(() => {
    axios
      .get(`${backendURL}/api/get/racksproductos`)
      .then((response) => {
        setRacksData(response.data);
      })
      .catch((error) => {
        console.error("Error al obtener datos:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = currentPage * itemsPerPage;
  // Filtra los elementos a mostrar en la página actual
  const visibleRacks = racksData.slice(startIndex, endIndex);

  useEffect(() => {
    updateButtonsToShow();
  }, [currentPage, racksData.length]);

  const updateButtonsToShow = () => {
    const totalPages = Math.ceil(racksData.length / itemsPerPage);
    let newButtonsToShow = 3;

    if (totalPages <= 3) {
      newButtonsToShow = totalPages;
    } else if (currentPage <= 2) {
      newButtonsToShow = 3;
    } else if (currentPage + 1 >= totalPages) {
      newButtonsToShow = totalPages;
    } else {
      newButtonsToShow = currentPage + 1;
    }

    setButtonsToShow(newButtonsToShow);
  };

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };
  const totalPagesFiltered = Math.ceil(racksData.length / itemsPerPage);

  return (
    <>{isLoading && <LoaderModal />} 
      <section>
        <section className="contenedor-titulo-racks">
          <img src={linea} alt="stacker" title="stacker" />
          <h1 className="titulo-racks"> conoce nuestro racks </h1>
        </section>
        <section className="catalogo-racks">
          <Grid container>
            {visibleRacks.map((rack, index) => (
              <Grid
                key={index}
                item
                xs={12}
                sm={6}
                md={4}
                className="catalogo-racks-producto"
              >  <a href={`/Rack/${rack.id}`} onClick={() => window.scrollTo(0, 0)}>

                  <div className="card-catalogo-rack">
                    {rack.imagen_sub_portada && (
                      <img
                        src={`${backendURL}/${rack.imagen_sub_portada}`}
                        alt={rack.alt_seo}
                        title={rack.alt_seo}
                        className="prodcutoracks"
                      />
                    )}
                    <div className="informacion-card-rack">
                      <h1 className="titulo-racks-catalogo">{rack.nombre}</h1>
                      <div className="info-titulo-racks">
                      <a href={`/Rack/${rack.id}`}>  <button className="button-catalogo-racks">
                          {" "}
                          VER MÁS{" "}
                        </button>
                         </a>
                      </div>
                    </div>
                  </div>
                </a>
              </Grid>
            ))}
          </Grid>
          <div className="pagination-buttons">
            <button
              onClick={() => handlePageClick(currentPage - 1)}
              className="prev-next"
              disabled={currentPage === 1}
            >
              <FaAngleLeft />
            </button>

            {Array.from({ length: buttonsToShow }, (_, i) => {
              const page = currentPage - 1 + i;
              // Si el número de página es mayor que el último conjunto de páginas disponibles, no mostrar el botón
              if (page + 1 > totalPagesFiltered || page + 1 > currentPage + 3) {
                return null;
              }

              return (
                <button
                  key={page + 1}
                  onClick={() => handlePageClick(page + 1)}
                  className={currentPage === page + 1 ? 'active' : ''}
                >
                  {page + 1}
                </button>
              );
            })}

            <button
              onClick={() => handlePageClick(currentPage + 1)}
              className="prev-next"
              disabled={currentPage === totalPagesFiltered}
            >
              <FaAngleRight />
            </button>
          </div>
        </section>
      </section>
    </>
  );
}