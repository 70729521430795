
import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { Link } from "react-router-dom";
import { FaAngleLeft } from "react-icons/fa";
import { FaAngleRight } from "react-icons/fa";
import "../../style/racks/racks.css";
import "../../style/producto/producto.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade, Pagination, Navigation } from "swiper/modules";
import LoaderModal from "../otroscomponentes/louding";
import axios from "axios";
import { GrVirtualMachine } from "react-icons/gr";
import { useLocation } from 'react-router-dom';

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import "swiper/css/navigation";


export default function Filtrar() {
  const [capacidadesBateria, setCapacidadesBateria] = useState([]); 
  const [filtroCapacidadBateria, setFiltroCapacidadBateria] = useState('');
  const [filtroCapacidad, setFiltroCapacidad] = useState('');
  const [mostrarFiltros, setMostrarFiltros] = useState(false);
  const [filtroCategoriaSeleccionada, setFiltroCategoriaSeleccionada] = useState(null);
const [alturaSeleccionada, setAlturaSeleccionada] = useState('');
      const [alturaRelacionadas, setalturaRelacionadas] = useState([]);
      const[filtroAltura,setfiltroAltura] = useState([])
      const [capacidadesRelacionadas, setCapacidadesRelacionadas] = useState([]);
      const [capacidadFiltradas,setCapacidadfiltrado] = useState([]);
      const [capacidadBateriaSeleccionada, setCapacidadBateriaSeleccionada] = useState('');
      const [capacidadBateriaTipos, setCapacidadBateriaTipos] = useState([]);
      const [filtroBateria, setFiltroBateria] = useState('');
      const [productosFiltrados, setProductosFiltrados] = useState([]);
      const [categoriaSeleccionada, setCategoriaSeleccionada] = useState(null);
      const [productos, setProductos] = useState([]);
      const [categorias, setCategorias] = useState([]);
      const [isLoading, setIsLoading] = useState(true);
      const [tiposBateria, setTiposBateria] = useState([]);
      const [tipoBateriaSeleccionado, setTipoBateriaSeleccionado] = useState('');
      const [seleccionDesdeBoton, setSeleccionDesdeBoton] = useState(false);
      const backendURL = process.env.REACT_APP_BACKEND_URL;
      const location = useLocation();
      const queryParams = new URLSearchParams(location.search);
      const selectedProductId = queryParams.get('id');

      
      const limpiarFiltros = () => {
        setCategoriaSeleccionada(null);
        setTipoBateriaSeleccionado('');
        setCapacidadBateriaSeleccionada('');
        setCapacidadfiltrado('');
        setFiltroCategoriaSeleccionada(null);
        setFiltroCapacidadBateria('');
        setFiltroCapacidad('');
        setfiltroAltura([]);
        setCapacidadesRelacionadas([]);
        setCapacidadfiltrado([]);
        setCapacidadBateriaTipos([]);
        setProductosFiltrados([]);
      
        // Añade la siguiente línea para refrescar los productos filtrados
        setProductosFiltrados(filtrarPorCategoria(productos, null));
      };
    
      
      useEffect(() => {
        const fetchData = async () => {
        
      
          try {
            const responseProductos = await axios.get(`${backendURL}/api/get/all/productos`);
            const responseCategorias = await axios.get(`${backendURL}/api/get/all/categorias`);
      
            if (responseProductos.status === 200 && responseCategorias.status === 200) {
              const productosData = responseProductos.data.reverse();
              setProductos(productosData);
      
              const categoriasData = responseCategorias.data;
              setCategorias(categoriasData);
      
              let productosFiltradosPorCategoria;
      
              if (categoriaSeleccionada) {
                // Si hay una categoría seleccionada, úsala
                productosFiltradosPorCategoria = filtrarPorCategoria(productosData, categoriaSeleccionada);
              } else if (selectedProductId && !seleccionDesdeBoton) {
                // Si hay un ID seleccionado y no es una selección desde botón, úsalo como categoría
                productosFiltradosPorCategoria = filtrarPorCategoria(productosData, selectedProductId);
              } else {
                // Si no hay ID seleccionado o es una selección desde botón, usa la categoría normal
                productosFiltradosPorCategoria = filtrarPorCategoria(productosData, categoriaSeleccionada);
                // Restablecer el ID de producto a null
                setSeleccionDesdeBoton(false);
              }
      
             /*  const productosFiltradosFinal = selectedProductId
                ? productosFiltradosPorCategoria.filter((producto) => producto.id === selectedProductId)
                : productosFiltradosPorCategoria;
      
              setProductosFiltrados(productosFiltradosFinal); */
      
              productosFiltradosPorCategoria = filtrarPorBateria(productosFiltradosPorCategoria, filtroBateria);
      
              setProductosFiltrados(productosFiltradosPorCategoria.reverse());
              setIsLoading(false);
      
              const tiposBateriaUnicos = [...new Set(productosFiltradosPorCategoria.map(producto => producto.bateria))];
              setTiposBateria(tiposBateriaUnicos);
              console.log("baterias", tiposBateriaUnicos);
      
              setCapacidadBateriaTipos(capacidadBateriaTiposUnicos);
              const capacidadBateriaTiposUnicos = [...new Set(productosFiltradosPorCategoria.map(producto => producto.capacidad_bateria))];
              console.log("capacidad baterias", capacidadBateriaTiposUnicos);
      
              const capacidadfiltrado = [...new Set(productosFiltradosPorCategoria.map(producto => producto.capacidad))];
              setCapacidadfiltrado(capacidadfiltrado);
              console.log("capacidad", capacidadfiltrado);
      
              const alturafiltrado = [...new Set(productosFiltradosPorCategoria.map(producto => producto.altura))];
              setfiltroAltura(alturafiltrado);
              console.log("altura", alturafiltrado);
            } else {
              console.error("Error al obtener datos de productos o categorías");
            }
          } catch (error) {
            console.error("Error de red", error);
          }
        };
      
        fetchData();
      }, [categoriaSeleccionada, filtroBateria, selectedProductId, seleccionDesdeBoton]);
      const filtrarPorCategoria = (productos, categoriaId) => {
        if (categoriaId === null) {
          return productos;
        }
        const productosFiltrados = productos.filter((producto) => producto.id_categoria.toString() === categoriaId.toString());
        console.log('Filtrado por Categoría - IDs de productos relacionados:', productosFiltrados.map(producto => producto.id));
        return productosFiltrados;
      };
      
      const filtrarPorBateria = (productos, bateria) => {
        if (!bateria) {
          return productos;
        }
      
        const productosFiltrados = productos.filter((producto) => producto.bateria.toLowerCase() === bateria.toLowerCase());
        console.log('Filtrado por Batería - IDs de productos relacionados:', productosFiltrados.map(producto => producto.id));
      
        // Actualizar los tipos de capacidad_bateria al filtrar por batería
        const capacidadBateriaTiposUnicos = [...new Set(productosFiltrados.map(producto => producto.capacidad_bateria))];
        setCapacidadBateriaTipos(capacidadBateriaTiposUnicos);
        console.log("Capacidad de baterias", capacidadBateriaTiposUnicos);
      
        return productosFiltrados;
      };

      const filtrarPorCapacidadBateria = (productos, capacidadBateria) => {
        if (!capacidadBateria) {
          return productos; // Si no hay capacidad seleccionada, devuelve los productos sin cambios
        }
      
        const productosFiltrados = productos.filter(
          (producto) => producto.capacidad_bateria.toLowerCase() === capacidadBateria.toLowerCase());

        const capacidadBateriaTiposUnicos = [...new Set(productosFiltrados.map(producto => producto.capacidad))];
        setCapacidadBateriaTipos(capacidadBateriaTiposUnicos);
        console.log("Capacidad de baterias", capacidadBateriaTiposUnicos);
        
      
        console.log('Filtrado por Capacidad de Batería - IDs de productos relacionados:', productosFiltrados.map(producto => producto.id));
      
        return productosFiltrados;
      };
      
      
      const filtrarPorCapacidad = (productos, capacidad) => {
        if (!capacidad) {
          return productos; // Si no hay capacidad seleccionada, devuelve los productos sin cambios
        }
      
        const productosFiltrados = productos.filter(
          (producto) => String(producto.capacidad).toLowerCase() === String(capacidad).toLowerCase()
        );
        const alturaUnicos = [...new Set(productosFiltrados.map(producto => producto.altura))];
        setalturaRelacionadas(alturaUnicos); // Cambia aquí
        console.log("Altura filtrado final", alturaUnicos);
        console.log('Filtrado por Capacidad de Batería - IDs de productos relacionados:', productosFiltrados.map(producto => producto.id));
      
        return productosFiltrados;
      };
      const filtrarAltura = (productos, altura) => {
        if (!altura) {
          return productos; // Si no hay altura seleccionada, devuelve los productos sin cambios
        }
      
        const productosFiltrados = productos.filter(
          (producto) => producto.altura.toLowerCase() === altura.toLowerCase()
        );
      
        // Obtener y almacenar las alturas relacionadas a los productos filtrados
        const alturasRelacionadas = [...new Set(productosFiltrados.map(producto => producto.altura))];
        setalturaRelacionadas(alturasRelacionadas);
        console.log("Alturas relacionadas", alturasRelacionadas);
      
        console.log('Filtrado por Altura - IDs de productos relacionados:', productosFiltrados.map(producto => producto.id));
      
        return productosFiltrados;
      };

      const handleTipoBateriaChange = (tipoBateria) => {
        setTipoBateriaSeleccionado(tipoBateria);
      
        const productosFiltradosPorTipoBateria = filtrarPorBateria(productos, tipoBateria);
        const productosFiltradosPorCategoria = filtrarPorCategoria(productosFiltradosPorTipoBateria, categoriaSeleccionada);
      
        // Actualiza los tipos de capacidad_bateria después de filtrar por batería y categoría
        const capacidadBateriaTiposUnicos = [...new Set(productosFiltradosPorCategoria.map(producto => producto.capacidad_bateria))];
        setCapacidadBateriaTipos(capacidadBateriaTiposUnicos);
        console.log("Capacidad de baterias", capacidadBateriaTiposUnicos);
      
        // Aplica más filtros si es necesario
      
        setProductosFiltrados(productosFiltradosPorCategoria.reverse());
      };

      const handleCapacidadBateriaChange = (capacidadBateria) => {
        setCapacidadBateriaSeleccionada(capacidadBateria);
    
        // Filtrar productos por capacidad de batería seleccionada
        const productosFiltradosPorCapacidadBateria = filtrarPorCapacidadBateria(
          productos, // Utiliza la capacidad de batería actualizada
          capacidadBateria
        );
    
        // Filtrar por categoría
        const productosFiltradosPorCategoria = filtrarPorCategoria(productosFiltradosPorCapacidadBateria, categoriaSeleccionada);
    
        // Filtrar por tipo de batería
        const productosFiltradosPorTipoBateria = filtrarPorBateria(productosFiltradosPorCategoria, tipoBateriaSeleccionado);
    
        // Actualizar los tipos de capacidad_bateria después de filtrar por capacidad y categoría
        const capacidadBateriaTiposUnicos = [...new Set(productosFiltradosPorTipoBateria.map(producto => producto.capacidad_bateria))];
        setCapacidadBateriaTipos(capacidadBateriaTiposUnicos);
        console.log("Capacidad de baterías", capacidadBateriaTiposUnicos);
    
        // Obtener y almacenar las capacidades relacionadas a los productos filtrados
        const capacidadesRelacionadas = [...new Set(productosFiltradosPorTipoBateria.map(producto => producto.capacidad))];
        setCapacidadesRelacionadas(capacidadesRelacionadas);
        console.log("capacidad", capacidadesRelacionadas)
    
        setProductosFiltrados(productosFiltradosPorTipoBateria.reverse());
      };
    
      
      const handleCapacidadChange = (capacidad) => {
        console.log("Al seleccionar capacidad:", capacidad);
      
        // Filtrar productos por capacidad seleccionada
        const productosFiltradosPorCapacidad = filtrarPorCapacidad(
          productos, // Utiliza la capacidad actualizada
          capacidad
        );
      
        // Filtrar por categoría
        const productosFiltradosPorCategoria = filtrarPorCategoria(productosFiltradosPorCapacidad, categoriaSeleccionada);
      
        // Filtrar por tipo de batería
        const productosFiltradosPorTipoBateria = filtrarPorBateria(productosFiltradosPorCategoria, tipoBateriaSeleccionado);
      
        // Filtrar por capacidad de batería seleccionada
        const productosFiltradosPorCapacidadBateria = filtrarPorCapacidadBateria(
          productosFiltradosPorTipoBateria, // Utiliza la capacidad de batería actualizada
          capacidadBateriaSeleccionada
        );
      
        // Actualizar los tipos de capacidad_bateria después de filtrar por capacidad y categoría
        const capacidadBateriaTiposUnicos = [...new Set(productosFiltradosPorCapacidadBateria.map(producto => producto.capacidad))];
        setCapacidadBateriaTipos(capacidadBateriaTiposUnicos);
        console.log("Capacidad de baterías", capacidadBateriaTiposUnicos);
      
        // Obtener y almacenar las capacidades relacionadas a los productos filtrados
        const capacidadesRelacionadas = [...new Set(productosFiltradosPorTipoBateria.map(producto => producto.altura))];
        setalturaRelacionadas(capacidadesRelacionadas);
        console.log("Altura relacionada", capacidadesRelacionadas);
      
        setProductosFiltrados(productosFiltradosPorCapacidadBateria.reverse());
      };

      const handleAlturaChange = (alturas) => {
  console.log("Al seleccionar alturas:", alturas);

  // Filtrar productos por alturas seleccionadas
  let productosFiltradosPorAlturas = productosFiltrados;

  if (alturas.length > 0) {
    productosFiltradosPorAlturas = productosFiltradosPorAlturas.filter(
      (producto) => alturas.includes(producto.altura.toLowerCase())
    );
  }

  // Obtener y almacenar las alturas relacionadas a los productos filtrados
  const alturasRelacionadas = [...new Set(productosFiltradosPorAlturas.map(producto => producto.altura))];
  setalturaRelacionadas(alturasRelacionadas);
  console.log("Alturas relacionadas", alturasRelacionadas);

  console.log('Filtrado por Alturas - IDs de productos relacionados:', productosFiltradosPorAlturas.map(producto => producto.id));

  // Actualizar los productos filtrados después de filtrar por alturas
  setProductosFiltrados(productosFiltradosPorAlturas.reverse());
};
      
      

  const toggleFiltros = () => {
    setMostrarFiltros(!mostrarFiltros);
    if (!mostrarFiltros) {
      // Si se hace clic en "titulo-catalogo-producto", no se reinician los filtros
      setCurrentPage(1); // Restablecer la página a la primera
    }
  };

  const numElementosPorFila = mostrarFiltros ? 4 : 3;

  const filtroGridProps = mostrarFiltros ? "show-filters" : "hide-filters";
  const productosGridProps = mostrarFiltros
    ? { xs: 12, sm: 6, md: 9 }
    : { xs: 12, sm: 6, md: 12 };

    const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = currentPage * itemsPerPage;

  const [buttonsToShow, setButtonsToShow] = useState(3);

  const productosActuales = productos.slice(startIndex, endIndex);

  useEffect(() => {
    updateButtonsToShow();
  }, [currentPage, productos.length]);

  const updateButtonsToShow = () => {
    const totalPages = Math.ceil(productos.length / itemsPerPage);
    let newButtonsToShow = 3;

    if (totalPages <= 3) {
      newButtonsToShow = totalPages;
    } else if (currentPage <= 2) {
      newButtonsToShow = 3;
    } else if (currentPage + 1 >= totalPages) {
      newButtonsToShow = totalPages;
    } else {
      newButtonsToShow = currentPage + 1;
    }

    setButtonsToShow(newButtonsToShow);
  };

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };
  const totalPagesFiltered = Math.ceil(productosFiltrados.length / itemsPerPage);
  return (
    <>
     {isLoading ? (
     <LoaderModal />
    ) : (
      <>
      
      <section className="categoria-prducto">
  <Grid container className="contiene-categoria-filtrado-uno">
    {categorias.map((categoria) => (
      <Grid key={categoria.id} xs={6} sm={4} className="categoria-maquina">
        <div style={{padding:"10px"}}>
          <button onClick={() => setCategoriaSeleccionada(categoria.id)} class="buttoncategoria">
            <GrVirtualMachine />
            {categoria.nombre}
            <div class="arrow">›</div>
          </button>
        </div>
      </Grid>
    ))}
  </Grid>
</section>
      <section className="principal filtrado">
      <div className="contenedor filtrar">
  <div className="contenedor-filtrado">
    <label className="burger" htmlFor="burger"  /*  onClick={limpiarFiltros} */ > 
       <input type="checkbox" id="burger" onClick={toggleFiltros} />
      <p>FILTRAR</p>
      <span></span>
      <span></span>
      <span></span>
    </label>
  </div>
</div>
      </section>

      <section className="section-productos">
        <Grid container spacing={2} className="productosfiltrados">
          <Grid item className={`transition-container ${filtroGridProps}`}>
       
          
  {mostrarFiltros && (
    <ul className="opciones-filtrado">
    
    
      <li>
        <label>
          <p className="titulo-select-producto">Equipo</p>
          <select
  className="select-producto"
  value={categoriaSeleccionada}
  onChange={(e) => {
    setCategoriaSeleccionada(e.target.value);
    setFiltroCategoriaSeleccionada(e.target.value);
  }}
>
  <option value="">Seleccionar </option>
  {categorias.map((categoria) => (
    <option key={categoria.id} value={categoria.id}>
      {categoria.nombre}
    </option>
  ))}
</select>
        </label>
      </li>
    
  
      <li>
  <label>
    <p className="titulo-select-producto">Energía</p>
    <select
          className="select-producto"
            value={tipoBateriaSeleccionado}
            onChange={(e) => handleTipoBateriaChange(e.target.value)}
          >
            <option value="">Selecionar</option>
            {tiposBateria.map((tipo) => (
              <option key={tipo} value={tipo}>
                {tipo}
              </option>
            ))}
          </select>
          
    
  </label>
</li>


<li>
  <div>
    <label>
      <p className="titulo-select-producto">Capacidad de Energía</p>
    
        <select
          className="select-producto"
          value={capacidadBateriaSeleccionada}
          onChange={(e) => handleCapacidadBateriaChange(e.target.value)}
        >
          <option value="">Seleccione la capacidad de la batería</option>
          {capacidadBateriaTipos.map((capacidad) => (
    <option key={capacidad} value={capacidad}>
      {capacidad}
    </option>
  ))}
        </select>
      
    </label>
  </div>
</li>


<li>
  <label>
    <p className="titulo-select-producto">Capacidad</p>
   
    <select
      className="select-producto"
      value={capacidadFiltradas}
      onChange={(e) => handleCapacidadChange(e.target.value)}
      
    >
      <option value="">Seleccionar </option>
      {capacidadesRelacionadas.map((capacidad) => (
    <option key={capacidad} value={capacidad}>
      {capacidad}
    </option>
  ))}
      
    </select>
    
  </label>
</li>

  <li>
    
      <div>
        <label>
          <p className="titulo-select-producto">Altura</p>
          <select
            className="select-producto"
            value={alturaSeleccionada}
            onChange={(e) => {
              setAlturaSeleccionada(e.target.value);
              handleAlturaChange(e.target.value);
            }}
           
          >
            <option value="">Seleccionar </option>
            {alturaRelacionadas.map((altura) => (
    <option key={altura} value={altura}>
      {altura}
    </option>
  ))}
             
          </select>
         
        </label>
      </div>
    
  </li>

 <li>
      <button className="limpiar-filtros"  onClick={limpiarFiltros} >
        Limpiar filtros
      </button>
    </li>
    </ul>
  )}
          </Grid>

          <Grid item {...productosGridProps}>
          <Grid container spacing={2}>
         
          {productosFiltrados.slice(startIndex, endIndex).map((producto, index) => (
                <Grid key={index} item xs={12} sm={12} md={numElementosPorFila}>
             <a href={`/Producto/${producto.id}`} onClick={() => window.scrollTo(0, 0)}>
  <div className="card-producto">
    <div className="container-imagen-producto">
      {producto.imagen && (
        <img
          src={`${backendURL}/${producto.imagen}`}
          alt={producto.seo || producto.nombre}
          className="maquinaselecion"
        />
      )}
    </div>
    <div className="informacion-productos">
      <h1 className="titulo-producto">{producto.nombre}</h1>
      <hr className="separador-producto" />
      <span className="informacion-producto">Precio desde {producto.precio_minimo}</span>
      <button className="cotizar-producto">COTIZAR </button>
    </div>
  </div>
</a>

                </Grid>
                
              ))}
            </Grid>
          </Grid>
        </Grid>
      </section>
      <div className="pagination-buttons">
  <button
    onClick={() => handlePageClick(currentPage - 1)}
    className="prev-next"
    disabled={currentPage === 1}
  >
    <FaAngleLeft />
  </button>

  {Array.from({ length: buttonsToShow }, (_, i) => {
  const page = currentPage - 1 + i;
  // Si el número de página es mayor que el último conjunto de páginas disponibles, no mostrar el botón
  if (page + 1 > totalPagesFiltered || page + 1 > currentPage + 3) {
    return null;
  }

  return (
    <button
      key={page + 1}
      onClick={() => handlePageClick(page + 1)}
      className={currentPage === page + 1 ? 'active' : ''}
    >
      {page + 1}
    </button>
  );
})}

<button
  onClick={() => handlePageClick(currentPage + 1)}
  className="prev-next"
  disabled={currentPage === totalPagesFiltered}
>
  <FaAngleRight />
</button>
</div>
</>
 )}
    </>
  );
} 
 

  /*   const Filtrar = () => 
    { 
      const [alturaSeleccionada, setAlturaSeleccionada] = useState('');
      const [alturaRelacionadas, setalturaRelacionadas] = useState([]);
      const[filtroAltura,setfiltroAltura] = useState([])
      const [capacidadesRelacionadas, setCapacidadesRelacionadas] = useState([]);
      const [capacidadFiltradas,setCapacidadfiltrado] = useState([]);
      const [capacidadBateriaSeleccionada, setCapacidadBateriaSeleccionada] = useState('');
      const [capacidadBateriaTipos, setCapacidadBateriaTipos] = useState([]);
      const [filtroBateria, setFiltroBateria] = useState('');
      const [productosFiltrados, setProductosFiltrados] = useState([]);
      const [categoriaSeleccionada, setCategoriaSeleccionada] = useState(null);
      const [productos, setProductos] = useState([]);
      const [categorias, setCategorias] = useState([]);
      const [isLoading, setIsLoading] = useState(true);
      const [tiposBateria, setTiposBateria] = useState([]);
      const [tipoBateriaSeleccionado, setTipoBateriaSeleccionado] = useState('');
      const [seleccionDesdeBoton, setSeleccionDesdeBoton] = useState(false);
      const backendURL = process.env.REACT_APP_BACKEND_URL;
      const location = useLocation();
      const queryParams = new URLSearchParams(location.search);
      const selectedProductId = queryParams.get('id');
    
      
      useEffect(() => {
        const fetchData = async () => {
          console.log('ID recibido desde la URL:', selectedProductId);
      
          try {
            const responseProductos = await axios.get(`${backendURL}/api/get/all/productos`);
            const responseCategorias = await axios.get(`${backendURL}/api/get/all/categorias`);
      
            if (responseProductos.status === 200 && responseCategorias.status === 200) {
              const productosData = responseProductos.data.reverse();
              setProductos(productosData);
      
              const categoriasData = responseCategorias.data;
              setCategorias(categoriasData);
      
              let productosFiltradosPorCategoria;
      
              if (selectedProductId && !seleccionDesdeBoton) {
                // Si hay un ID seleccionado y no es una selección desde botón, úsalo como categoría
                productosFiltradosPorCategoria = filtrarPorCategoria(productosData, selectedProductId);
              } else {
                // Si no hay ID seleccionado o es una selección desde botón, usa la categoría normal
                productosFiltradosPorCategoria = filtrarPorCategoria(productosData, categoriaSeleccionada);
                // Restablecer el ID de producto a null
                setSeleccionDesdeBoton(false);
              }
      
              const productosFiltradosFinal = selectedProductId
                ? productosFiltradosPorCategoria.filter((producto) => producto.id === selectedProductId)
                : productosFiltradosPorCategoria;
      
              setProductosFiltrados(productosFiltradosFinal);
      
              productosFiltradosPorCategoria = filtrarPorBateria(productosFiltradosPorCategoria, filtroBateria);
      
              setProductosFiltrados(productosFiltradosPorCategoria.reverse());
              setIsLoading(false);
      
              const tiposBateriaUnicos = [...new Set(productosFiltradosPorCategoria.map(producto => producto.bateria))];
              setTiposBateria(tiposBateriaUnicos);
              console.log("baterias", tiposBateriaUnicos);
      
              setCapacidadBateriaTipos(capacidadBateriaTiposUnicos);
              const capacidadBateriaTiposUnicos = [...new Set(productosFiltradosPorCategoria.map(producto => producto.capacidad_bateria))];
              console.log("capacidad baterias", capacidadBateriaTiposUnicos);
      
              const capacidadfiltrado = [...new Set(productosFiltradosPorCategoria.map(producto => producto.capacidad))];
              setCapacidadfiltrado(capacidadfiltrado);
              console.log("capacidad", capacidadfiltrado);
      
              const alturafiltrado = [...new Set(productosFiltradosPorCategoria.map(producto => producto.altura))];
              setfiltroAltura(alturafiltrado);
              console.log("altura", alturafiltrado);
            } else {
              console.error("Error al obtener datos de productos o categorías");
            }
          } catch (error) {
            console.error("Error de red", error);
          }
        };
      
        fetchData();
      }, [categoriaSeleccionada, filtroBateria, selectedProductId]);
      const filtrarPorCategoria = (productos, categoriaId) => {
        if (categoriaId === null) {
          return productos;
        }
        const productosFiltrados = productos.filter((producto) => producto.id_categoria.toString() === categoriaId.toString());
        console.log('Filtrado por Categoría - IDs de productos relacionados:', productosFiltrados.map(producto => producto.id));
        return productosFiltrados;
      };
      
      const filtrarPorBateria = (productos, bateria) => {
        if (!bateria) {
          return productos;
        }
      
        const productosFiltrados = productos.filter((producto) => producto.bateria.toLowerCase() === bateria.toLowerCase());
        console.log('Filtrado por Batería - IDs de productos relacionados:', productosFiltrados.map(producto => producto.id));
      
        // Actualizar los tipos de capacidad_bateria al filtrar por batería
        const capacidadBateriaTiposUnicos = [...new Set(productosFiltrados.map(producto => producto.capacidad_bateria))];
        setCapacidadBateriaTipos(capacidadBateriaTiposUnicos);
        console.log("Capacidad de baterias", capacidadBateriaTiposUnicos);
      
        return productosFiltrados;
      };

      const filtrarPorCapacidadBateria = (productos, capacidadBateria) => {
        if (!capacidadBateria) {
          return productos; // Si no hay capacidad seleccionada, devuelve los productos sin cambios
        }
      
        const productosFiltrados = productos.filter(
          (producto) => producto.capacidad_bateria.toLowerCase() === capacidadBateria.toLowerCase());

        const capacidadBateriaTiposUnicos = [...new Set(productosFiltrados.map(producto => producto.capacidad))];
        setCapacidadBateriaTipos(capacidadBateriaTiposUnicos);
        console.log("Capacidad de baterias", capacidadBateriaTiposUnicos);
        
      
        console.log('Filtrado por Capacidad de Batería - IDs de productos relacionados:', productosFiltrados.map(producto => producto.id));
      
        return productosFiltrados;
      };
      
      
      const filtrarPorCapacidad = (productos, capacidad) => {
        if (!capacidad) {
          return productos; // Si no hay capacidad seleccionada, devuelve los productos sin cambios
        }
      
        const productosFiltrados = productos.filter(
          (producto) => String(producto.capacidad).toLowerCase() === String(capacidad).toLowerCase()
        );
        const alturaUnicos = [...new Set(productosFiltrados.map(producto => producto.altura))];
        setalturaRelacionadas(alturaUnicos); // Cambia aquí
        console.log("Altura filtrado final", alturaUnicos);
        console.log('Filtrado por Capacidad de Batería - IDs de productos relacionados:', productosFiltrados.map(producto => producto.id));
      
        return productosFiltrados;
      };
      const filtrarAltura = (productos, altura) => {
        if (!altura) {
          return productos; // Si no hay altura seleccionada, devuelve los productos sin cambios
        }
      
        const productosFiltrados = productos.filter(
          (producto) => producto.altura.toLowerCase() === altura.toLowerCase()
        );
      
        // Obtener y almacenar las alturas relacionadas a los productos filtrados
        const alturasRelacionadas = [...new Set(productosFiltrados.map(producto => producto.altura))];
        setalturaRelacionadas(alturasRelacionadas);
        console.log("Alturas relacionadas", alturasRelacionadas);
      
        console.log('Filtrado por Altura - IDs de productos relacionados:', productosFiltrados.map(producto => producto.id));
      
        return productosFiltrados;
      };

      const handleTipoBateriaChange = (tipoBateria) => {
        setTipoBateriaSeleccionado(tipoBateria);
      
        const productosFiltradosPorTipoBateria = filtrarPorBateria(productos, tipoBateria);
        const productosFiltradosPorCategoria = filtrarPorCategoria(productosFiltradosPorTipoBateria, categoriaSeleccionada);
      
        // Actualiza los tipos de capacidad_bateria después de filtrar por batería y categoría
        const capacidadBateriaTiposUnicos = [...new Set(productosFiltradosPorCategoria.map(producto => producto.capacidad_bateria))];
        setCapacidadBateriaTipos(capacidadBateriaTiposUnicos);
        console.log("Capacidad de baterias", capacidadBateriaTiposUnicos);
      
        // Aplica más filtros si es necesario
      
        setProductosFiltrados(productosFiltradosPorCategoria.reverse());
      };

      const handleCapacidadBateriaChange = (capacidadBateria) => {
        setCapacidadBateriaSeleccionada(capacidadBateria);
    
        // Filtrar productos por capacidad de batería seleccionada
        const productosFiltradosPorCapacidadBateria = filtrarPorCapacidadBateria(
          productos, // Utiliza la capacidad de batería actualizada
          capacidadBateria
        );
    
        // Filtrar por categoría
        const productosFiltradosPorCategoria = filtrarPorCategoria(productosFiltradosPorCapacidadBateria, categoriaSeleccionada);
    
        // Filtrar por tipo de batería
        const productosFiltradosPorTipoBateria = filtrarPorBateria(productosFiltradosPorCategoria, tipoBateriaSeleccionado);
    
        // Actualizar los tipos de capacidad_bateria después de filtrar por capacidad y categoría
        const capacidadBateriaTiposUnicos = [...new Set(productosFiltradosPorTipoBateria.map(producto => producto.capacidad_bateria))];
        setCapacidadBateriaTipos(capacidadBateriaTiposUnicos);
        console.log("Capacidad de baterías", capacidadBateriaTiposUnicos);
    
        // Obtener y almacenar las capacidades relacionadas a los productos filtrados
        const capacidadesRelacionadas = [...new Set(productosFiltradosPorTipoBateria.map(producto => producto.capacidad))];
        setCapacidadesRelacionadas(capacidadesRelacionadas);
        console.log("capacidad", capacidadesRelacionadas)
    
        setProductosFiltrados(productosFiltradosPorTipoBateria.reverse());
      };
    
      
      const handleCapacidadChange = (capacidad) => {
        console.log("Al seleccionar capacidad:", capacidad);
      
        // Filtrar productos por capacidad seleccionada
        const productosFiltradosPorCapacidad = filtrarPorCapacidad(
          productos, // Utiliza la capacidad actualizada
          capacidad
        );
      
        // Filtrar por categoría
        const productosFiltradosPorCategoria = filtrarPorCategoria(productosFiltradosPorCapacidad, categoriaSeleccionada);
      
        // Filtrar por tipo de batería
        const productosFiltradosPorTipoBateria = filtrarPorBateria(productosFiltradosPorCategoria, tipoBateriaSeleccionado);
      
        // Filtrar por capacidad de batería seleccionada
        const productosFiltradosPorCapacidadBateria = filtrarPorCapacidadBateria(
          productosFiltradosPorTipoBateria, // Utiliza la capacidad de batería actualizada
          capacidadBateriaSeleccionada
        );
      
        // Actualizar los tipos de capacidad_bateria después de filtrar por capacidad y categoría
        const capacidadBateriaTiposUnicos = [...new Set(productosFiltradosPorCapacidadBateria.map(producto => producto.capacidad))];
        setCapacidadBateriaTipos(capacidadBateriaTiposUnicos);
        console.log("Capacidad de baterías", capacidadBateriaTiposUnicos);
      
        // Obtener y almacenar las capacidades relacionadas a los productos filtrados
        const capacidadesRelacionadas = [...new Set(productosFiltradosPorTipoBateria.map(producto => producto.altura))];
        setalturaRelacionadas(capacidadesRelacionadas);
        console.log("Altura relacionada", capacidadesRelacionadas);
      
        setProductosFiltrados(productosFiltradosPorCapacidadBateria.reverse());
      };

      const handleAlturaChange = (alturas) => {
  console.log("Al seleccionar alturas:", alturas);

  // Filtrar productos por alturas seleccionadas
  let productosFiltradosPorAlturas = productosFiltrados;

  if (alturas.length > 0) {
    productosFiltradosPorAlturas = productosFiltradosPorAlturas.filter(
      (producto) => alturas.includes(producto.altura.toLowerCase())
    );
  }

  // Obtener y almacenar las alturas relacionadas a los productos filtrados
  const alturasRelacionadas = [...new Set(productosFiltradosPorAlturas.map(producto => producto.altura))];
  setalturaRelacionadas(alturasRelacionadas);
  console.log("Alturas relacionadas", alturasRelacionadas);

  console.log('Filtrado por Alturas - IDs de productos relacionados:', productosFiltradosPorAlturas.map(producto => producto.id));

  // Actualizar los productos filtrados después de filtrar por alturas
  setProductosFiltrados(productosFiltradosPorAlturas.reverse());
};
      
      
      
  return (
    <div className="contenedor-filtrar">
      <div className="contenedor-botones-categoria">
        <button onClick={() => setCategoriaSeleccionada(null)}>Mostrar Todos</button>
        {categorias.map((categoria) => (
          <button
            key={categoria.id}
            onClick={() => setCategoriaSeleccionada(categoria.id)}
            className={categoriaSeleccionada === categoria.id ? 'activo' : ''}
          >
            {categoria.nombre}
          </button>
        ))}
      </div>

      <div className="contenedor-botones-filtro">
        <select
          value={tipoBateriaSeleccionado}
          onChange={(e) => handleTipoBateriaChange(e.target.value)}
        >
          <option value="">Todos los tipos de batería</option>
          {tiposBateria.map((tipo) => (
            <option key={tipo} value={tipo}>
              {tipo}
            </option>
          ))}
        </select>

     
        <select
  value={capacidadBateriaSeleccionada}
  onChange={(e) => handleCapacidadBateriaChange(e.target.value)}
>
  <option value="">Capacidad de batería</option>
  {capacidadBateriaTipos.map((capacidad) => (
    <option key={capacidad} value={capacidad}>
      {capacidad}
    </option>
  ))}
</select>
<select
  value={capacidadFiltradas}
  onChange={(e) => handleCapacidadChange(e.target.value)}
>
  <option value="">Capacidad</option>
  {capacidadesRelacionadas.map((capacidad) => (
    <option key={capacidad} value={capacidad}>
      {capacidad}
    </option>
  ))}
</select>
<select
  value={alturaSeleccionada}
  onChange={(e) => {
    setAlturaSeleccionada(e.target.value);
    handleAlturaChange(e.target.value);
  }}
>
  <option value="">Altura</option>
  {alturaRelacionadas.map((altura) => (
    <option key={altura} value={altura}>
      {altura}
    </option>
  ))}
</select>

      
      </div>

      {isLoading ? (
        <p>Cargando...</p>
      ) : (
        <div className="contenedor-productos">
          {productosFiltrados.map((producto) => (
            <div key={producto.id} className="producto">
              <p>{producto.nombre}</p>
       
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Filtrar;  */