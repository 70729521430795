
import React, { useState, useEffect } from "react";
import "../../style/home/home.css";
import axios from "axios";
 import LoaderModal from "../otroscomponentes/louding";
import { Grid } from "@mui/material";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import "swiper/css/navigation";

/* imagenes */

import Lineas from "../../img/home/contactohome/lineas.png";


export default function ClientesHome() {
  const [modalOpen, setModalOpen] = useState(false);
  const [cliente, setCliente] = useState([]);
  const [selectedClientUrl, setSelectedClientUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const backendURL = process.env.REACT_APP_BACKEND_URL;  
  useEffect(() => {
    // Realiza la solicitud a la API al cargar el componente
    axios.get(`${backendURL}/api/get/all/videos`)
      .then(response => {
        // Filtra los servicios que tienen tipo 'cliente' en su array
        const clientes = response.data.filter(video => video.tipo === 'cliente');
        setCliente(clientes);
      })
      .catch(error => {
        console.error('Error fetching servicios:', error);
      });
  }, []);

  const handleOpenModal = (clientUrl) => {
    setLoading(true);
    setModalOpen(true);
    setSelectedClientUrl(clientUrl);
  };

  const handleCloseModal = () => {
    setLoading(false);
    setModalOpen(false);
  };
  return (
    <section className="clientes-home-principal">
       <section className="titulo-nosotros-home" >
    <img src={Lineas} alt="stacker" title="stacker" />
    <h1 className="titulo-home-nosotros"> NUESTROS PRINCIPALES CLIENTES</h1>
      </section>
      <div className="clientes-home-testimonio">
        <Grid container className="slider-clientes-home">
          <Swiper
            slidesPerView={1}
            spaceBetween={1}
            breakpoints={{
              240: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              500: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              600: {
                slidesPerView: 1,
                spaceBetween: 1,
              },
              640: {
                slidesPerView: 2,
                spaceBetween: 1,
              },
              900: { slidesPerView: 2, spaceBetween: 1 },
              1565: {
                slidesPerView: 3,
                spaceBetween: 1,
              },
              1566: {
                slidesPerView: 3,
                spaceBetween: 1,
              },
            }}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
            
            className="mySwiper"
          >
            {cliente.map(clienteItem => (
  <SwiperSlide key={clienteItem.id} className="contenedor-cliente">
    {/* Resto del código */}
    <div className="contenedor-boton-cliente">
      <button
        className="button-video-cliente"
        onClick={() => handleOpenModal(clienteItem.url)}
      >
        <svg
          viewBox="0 0 448 512"
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
          width="26px"
        >
          <path
            d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z"
            fill="currentColor"
          ></path>
        </svg>
      </button>
    </div>
    <div className="card-container">
      {clienteItem.imagen && (
        <img 
          className="video-logo-imagen"
          src={`${backendURL}/${clienteItem.imagen}`}
          alt={clienteItem.alt_seo || clienteItem.titulo}
         
        />
      )}
      <div className="iluminado-overlay"></div>
      {clienteItem.logo && (
        <img
          className="img-logo-cliente-home"
          src={`${backendURL}/${clienteItem.logo}`}
          alt={clienteItem.alt_seo || clienteItem.titulo}
          
          
        />
      )}

              </div>
            </SwiperSlide>
             ))}
          </Swiper>
        </Grid>
      </div>
      {modalOpen && (
        <div className="modal-overlay" onClick={handleCloseModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            {loading ? (
              <div className="contiene-video-cliente">
              <iframe
                className="video-cliente"
                src={selectedClientUrl}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </div>
              
            ) : (
              <LoaderModal />
            )}
          </div>
        </div>
      )}
    </section>
  );
}
