import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";


import Portadaproducto from "../components/producto/portada"
import Filtrar from "../components/producto/filtrado"

export default function Productos() {
    
    return(
        <>
        <section><Portadaproducto/></section>
        <section><Filtrar/></section>
        
         
        </>
    )

}
