
import axios from 'axios';
import { Grid } from "@mui/material";
import "../../style/estructura racks/estrucrack.css"

import React from "react";
/* imagenes */
import check from "../../img/racks/estructura/check.png"


export default function VentajaRacks ( { rack} ) {
    
/* 

  if (!racksId) {
    return null;
  }

  const { video, descripcion_2 } = racksId; */

    return(
        <>
        <section>
            <Grid container className="ventajas-rack-estructura">
                
                <Grid xs={2} sm={2} className="separador-racks-principal"> 
                <div className="separador-linea-rack"></div>
                </Grid>
                <Grid xs={10} sm={10}> 
                <div className="contenedor-descripcion-rack">
                <h1 className="titulo-ventaja-estructura">descripción</h1>   
                
                <p className="descripcion-rack-final-estrcutura">
                {rack.descripcion_2}
                </p>
                </div>
                
                </Grid>
            </Grid>
        </section>
        <section className="video-racks-estructura">
            <div className="contenedor-video-racks">
            <iframe  className="videos-rack" src={rack.video} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
        </section>
        </>
    )
}