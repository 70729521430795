'use client';
import React, { useState, useEffect, useContext } from "react";
import "../../style/navbar/navbar.css"

import { Link } from "react-router-dom";
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { useCarrito } from "../context/carrito-cotizar"; 





/* material ui */

import Grid from '@mui/material/Grid';


/* import React Icons */
import { FaFacebookF, FaLinkedinIn, FaWhatsapp } from "react-icons/fa6";

/* imgnes */
import logo from "../../img/navbar/logo.png"


const links =[
  {
    label:'Racks',
    route:'/Catalogo'
},  
{
    label:'Servicios',
    route:'/Servicios'
},

{
    label:'Contacto',
    route:'/contacto'
},

]

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));

const scrollToTargetElement = (elementId) => {
  const targetElement = document.getElementById(elementId);
  if (targetElement) {
    targetElement.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }
};

export default function Navbar () {
   const { cantidadProductos } = useCarrito(); 
  const [carroVisible, setCarroVisible] = useState(false);

  const handleCartClick = () => {
    // Muestra o esconde el componente Carro
    setCarroVisible(!carroVisible);
  };

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  
  /*  const handleToggleDrawer = () => {
    toggleCartVisibility();
    console.log("Carrito abierto");
  };  */

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <div className="contiene-listado">
      <li className='ruta-nav-mobil' >
                        <a href="/Productos">
                        <p className="label-nav-mobil">Productos </p>
                        </a>
                        </li>
                            {links.map(({ label, route }) => (
                                <li className='ruta-nav-mobil' key={route} onClick={closeMenu}>
                                    <a  href={route}  >
                                        <p className="label-nav-mobil">{label} </p>
                                    </a>
                                </li>
                            ))}
                            </div>
      </List>
      <Divider />
      <List>
      <div className="conteneredor-redes-sociales-navbar">
      <a
                  href="https://wa.me/990792892?text=Quiero informacion o cotizar por sus servicios y productos"
                  target="_blank"
                >
                            <button className="icono-redes-navbar">
                                <FaWhatsapp className="iconos-navbar" />
                            </button>
                            </a>

                            <a
                  href="https://www.linkedin.com/company/stacker-itcl/"
                  target="_blank"
                >
                            <button className="icono-redes-navbar">
                                <FaLinkedinIn className="iconos-navbar" />
                            </button>
                            </a>

                            <a
                  href="https://www.facebook.com/StackerChile"
                  target="_blank"
                >
                            <button className="icono-redes-navbar">
                                <FaFacebookF className="iconos-navbar" />
                            </button>
                            </a>
                        </div>
      </List>
    </Box>
  );


  const handleClick = (e) => {
    e.preventDefault();
    const clickedId = e.currentTarget.getAttribute('id');
    console.log('Clicked ID:', clickedId);

    if (clickedId === 'nosotros') {
      // Redirige a la ruta principal ("/") y realiza el scroll después de un breve período
     
      setTimeout(() => scrollToTargetElement('conoce-maquinarias'), 100);
    }
  };


      const [menuVisible, setMenuVisible] = useState(true);

    const toggleMenu = () => {
        setMenuVisible(!menuVisible);
    }

    
  useEffect(() => {
    // Agregar un manejador de redimensión para controlar la visibilidad del menú móvil
    const handleResize = () => {
      if (window.innerWidth <= 840) {
        setMenuVisible(true);
      } else {
        setMenuVisible(false);
      }
    };

    // Llamar al manejador de redimensión al cargar la página
    handleResize();

    // Agregar el manejador de redimensión al evento de redimensionamiento de la ventana
    window.addEventListener('resize', handleResize);

    // Limpiar el manejador de redimensionamiento al desmontar el componente
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function toggleCuadrado() {
    const cuadradoDesplegable = document.getElementById("cuadrado-desplegable");
    const checkbox = document.getElementById("checkbox");
  
    if (checkbox.checked) {
      cuadradoDesplegable.style.display = "block";
    } else {
      cuadradoDesplegable.style.display = "none";
    }
  }
  const closeMenu = () => {
    setMenuVisible(false);
  };
 
  
  const [drawerOpen, setDrawerOpen] = useState(false);

 

  
    return(
        <header className="header">
         {menuVisible ? (
               <section className="contenedor-navbar menu-mobile">
               <Grid xs={9}>
              
                   <div className="contenedor-imgn">
                  
                    <a href="/">
                       <img src={logo} alt="maquinaria" className="logomaquina" />
                       </a></div>
               </Grid>
               <Grid xs={1}>
               <div className="contiene-icono-shop">
                
               <Link href="/cart">
                      <IconButton aria-label="cart" className="contiene-icono-shop">
  <StyledBadge  badgeContent={cantidadProductos}  style={{color:"white", }}>
    <ShoppingCartIcon style={{color:"white"}}/>
  </StyledBadge>
</IconButton></Link>
            </div>
                    </Grid>
               <Grid xs={2}>
                   <div className="contenedor-menu">
                   <input type="checkbox"  className='checkbox-navbar'id="checkbox"checked={state.right}
                      onChange={toggleDrawer("right", !state.right)}/>
    <label for="checkbox" className="toggle">
        <div className="bars" id="bar1"></div>
        <div className="bars" id="bar2"></div>
        <div className="bars" id="bar3"></div>
    </label>
    <SwipeableDrawer
                    className="listado-menu"
                    anchor="right"
                    open={state.right}
                    onClose={toggleDrawer("right", false)}
                    onOpen={toggleDrawer("right", true)}
                  >
                    {list("right")}
                  </SwipeableDrawer>
                  </div>
               </Grid>
            
           </section>
             
      ) : (
               
<section className="contenedor-navbar">
                    <Grid xs={2}>
                        <div className="contenedor-imgn">
                      
                          <a href="/">
                            <img src={logo} alt="maquinaria" className="logomaquina" />
                            </a>
                        </div>
                    </Grid>
                    <Grid lg={6} spacing={4}>
                        <ul className="rutas-navbar">
                          
                        <li className='ruta-nav' >
                        <a className='link-ruta-nav' id="nosotros" href="/" onClick={handleClick}>
      <p className='label-nav'>Nosotros</p>
    </a>
</li>
                        <li className='ruta-nav' >
                        <a className='link-ruta-nav' href="/Productos">
                        <p className="label-nav">Productos </p>
                        </a>
                        </li>
                            {links.map(({ label, route }) => (
                                <li className='ruta-nav' key={route}>
                                    <a className='link-ruta-nav' href={route}>
                                        <p className="label-nav">{label} </p>
                                    </a>
                                </li>
                            ))}
                           
                        </ul>
                    </Grid>
                    <Grid xs={1}>
                      <div  > 
                      <a href="/cart">
                      <IconButton aria-label="cart" className="contiene-icono-shop">
  <StyledBadge  badgeContent={cantidadProductos}  style={{color:"white", }}>
    <ShoppingCartIcon style={{color:"white"}}/>
  </StyledBadge>
</IconButton></a></div>
                    </Grid>
                    <Grid xs={3}>
                        <div className="conteneredor-redes-sociales-navbar">
                            <button className="icono-redes-navbar">
                                <FaWhatsapp className="iconos-navbar" />
                            </button>
                            <button className="icono-redes-navbar">
                                <FaLinkedinIn className="iconos-navbar" />
                            </button>
                            <button className="icono-redes-navbar">
                                <FaFacebookF className="iconos-navbar" />
                            </button>
                        </div>
                    </Grid>
                </section>
            )}
          
  
        </header>
    )
}
