import React from "react";
import CircularProgress from '@mui/material/CircularProgress';
import "../../style/otros/louding.css";

const LoaderModal = () => {
  return (
    <div className="overlay">
      <div className="modal">
        <CircularProgress color="success" style={{width:"150px", height:"auto"}} />
      </div>
    </div>
  );
};

export default LoaderModal;