import React from "react";
import { Grid } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade,  } from "swiper/modules";
import Contactoformulario from "./formulario";
import { FaFacebookF, FaLinkedinIn, FaWhatsapp } from "react-icons/fa6";
import { MdOutlineEmail } from "react-icons/md";
import { AiOutlinePhone } from "react-icons/ai";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import "swiper/css/navigation";


/* imagenes */
import grua from"../../img/home/categoria/grua.png"
import apilador from"../../img/home/categoria/apilador.png"
import reach from"../../img/home/categoria/reach.png"
import traspaleta from"../../img/home/categoria/traspaleta.png"
import fondo from "../../img/contacto/fondo.png"



export default function ContactoPrincipal () {
  
    return(
        <section  style={{
          background: `url(${fondo})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          margin: '0px 0px -30px',
        }}>
        <Grid container className="contiene-contacto-completo">
        <Grid sm={12} md={6}>
          <div className="slider-contacto-imagen">
        <Swiper
      slidesPerView={1}
      spaceBetween={1} 
      autoplay={{
        delay: 2000,
        disableOnInteraction: false,
      }}
      modules={[Autoplay]}
      loop={true} // Hace que el Swiper sea infinito
      className="mySwiper"
    >
           <SwiperSlide>
            <div className='contenedor-maquina-portada-contacto'><img src={grua} alt='stacker' title='stacker'className="contenedormaquinacontacto"/></div>
           </SwiperSlide>
           <SwiperSlide>
           <div className='contenedor-maquina-portada-contacto'><img src={apilador} alt='stacker' title='stacker'className="contenedormaquinacontacto"/></div>
           </SwiperSlide>
           <SwiperSlide>
           <div className='contenedor-maquina-portada-contacto'><img src={reach} alt='stacker' title='stacker'className="contenedormaquinacontacto"/></div>
           </SwiperSlide>
           <SwiperSlide> <div className='contenedor-maquina-portada-contacto'><img src={traspaleta} alt='stacker' title='stacker'className="contenedormaquinacontacto"/></div></SwiperSlide>
          
           
        </Swiper>  
</div> 
<div className="contienen-contacto-redes-sociales"> 
<div className="conteneredor-redes-sociales-contacto">
                            <button className="contacto-icono-redes">
                            <a
                  href="https://wa.me/990792892?text=Quiero informacion o cotizar por sus servicios y productos"
                  target="_blank"
                >
                                <FaWhatsapp className="iconos-contacto" />
                                </a>
                            </button>
                            <button className="contacto-icono-redes">
                            <a
                  href="https://www.linkedin.com/company/stacker-itcl/"
                  target="_blank"
                >
                            
                                <FaLinkedinIn className="iconos-contacto" />
                                </a>
                            </button>
                            <button className="contacto-icono-redes">
                            <a
                  href="https://www.facebook.com/StackerChile"
                  target="_blank"
                >  <FaFacebookF className="iconos-contacto" />
                </a>
                            </button>

                           
                            <button className="contacto-icono-redes-1">
                            <a href="mailto:contacto@stacker.cl" target="_blank">
                                <MdOutlineEmail  className="iconos-contacto-1" />
                            </a>
                            </button>
                            <button className="contacto-icono-redes-1">
                            <a href="tel:990792892" target="_blank">
                                <AiOutlinePhone className="iconos-contacto-1" />
                          </a>
                            </button>
                        </div>
 </div>
        </Grid>
        <Grid sm={12} md={6} className="contieneformulario">
            <div className="contiene-formulario">
        <Contactoformulario/>
</div>
        </Grid>

        </Grid>
        </section>
    )
}