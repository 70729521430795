import React from "react";
import "../../style/footer/footer.css";
import { Link } from "react-router-dom";
/* import React Icons */
import { FaFacebookF, FaLinkedinIn, FaWhatsapp } from "react-icons/fa6";
import { BsTelephoneFill, BsEnvelope } from "react-icons/bs";
import { Grid } from "@mui/material";

/* imagenes */
import Logo from "../../img/footer/logofooter.png";

export default function Footer() {
  return (
    <section className="footer">
        <div className="contenedor-footer">
      <Grid container className="footer-contenedor">
        <Grid xs={12} sm={12} md={4}>
          <div className="contenedor-img-footer">
            <div className="logo-footer">
              {" "}
              <img src={Logo} className="logofooter" />
            </div>
            <div className="redes-footer">
              <button className="icono-redes-footer">
                <a
                  href="https://wa.me/990792892?text=Quiero informacion o cotizar por sus servicios y productos"
                  target="_blank"
                >
                  <FaWhatsapp className="iconos-footer" />
                </a>
              </button>
              <a
                  href="https://www.linkedin.com/company/stacker-itcl/"
                  target="_blank"
                >
              <button className="icono-redes-footer">
                <FaLinkedinIn className="iconos-footer" />
              </button></a>
              <a
                  href="https://www.facebook.com/StackerChile"
                  target="_blank"
                >
              <button className="icono-redes-footer">
                <FaFacebookF className="iconos-footer" />
              </button>
              </a>
            </div>
          </div>
        </Grid>
        <Grid xs={12} sm={12} md={4}>
          <div className="info-footer">
            <h1 className="titulo-footer">CONTACTO</h1>
            <hr className="tit-footer" />
            <ul>
              <li className="contacto-footer">
                {" "}
                <a href="mailto:contacto@stacker.cl" target="_blank">
                  <BsEnvelope className="redes-sociales-footer-svg" />
                  <p className="info-lista-contacto-footer">
                    contacto@stacker.cl
                  </p>{" "}
                </a>
              </li>
              <li className="contacto-footer">
                {" "}
                <a href="tel:990792892" target="_blank">
                  <BsTelephoneFill className="redes-sociales-footer-svg" />
                  <p className="info-lista-contacto-footer">
                    +569 9079 2892
                  </p>{" "}
                </a>
              </li>
            </ul>
          </div>
        </Grid>
        <Grid xs={12} sm={12} md={4}>
          <div className="info-footer-dos">
            <h1 className="titulo-footer">SERVICIOS</h1>
            <hr className="tit-footer" />
            <ul>
              <li className="contacto-footer-dos">
                <Link href="/Servicios">
                <p className="info-lista-contacto-footer">Arriendo</p>{" "}</Link>
              </li>
              <li className="contacto-footer-dos">
              <Link href="/Servicios">
                <p className="info-lista-contacto-footer">Venta</p>{" "}</Link>
              </li>
              <li className="contacto-footer-dos">
              <Link href="/Servicios">
                <p className="info-lista-contacto-footer">Racks</p>{" "}
                </Link>
              </li>
              <li className="contacto-footer-dos">
              <Link href="/Servicios">
                <p className="info-lista-contacto-footer">
                  Servicio Tecnico
                </p>{" "}
                </Link>
              </li>
              <li className="contacto-footer-dos">
              <Link href="/Catalogo">
                <p className="info-lista-contacto-footer">Catálogo</p>{" "}
                </Link>
              </li>
            </ul>
          </div>{" "}
        </Grid>
      </Grid>
      <Grid container className="footer-final">
        <hr className="separador-footer"/>
        <span className="info-final-footer"> STACKER 2024.</span>
      </Grid>
      </div>
    </section>
  );
}
