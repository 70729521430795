
import React, { useEffect, useState } from 'react';
import { Grid } from "@mui/material"
import axios from 'axios';
import"../../style/estrcutura producto/productestruc.css"
import "../../style/estructura racks/estrucrack.css"

/* imagenes */

import check from "../../img/racks/estructura/check.png"

export default function Descripcionproducto({ producto }) {
 
    return (
        <>
       
     
        <section className="section-descripcion-general">

            <div className="titulo-descripcion-general-prducto">
                <img className="imagen-titulo-generalprducto" src={check} alt="stacker" title="stacker" />
                <h1 className="titulo-descripcion-general">CARACTERISTICAS ESPECIALES</h1>
                </div>
        </section>
        <section className='section-caracterizacion-prducto'>
            <div className='texto-caracterizacion'>
          {producto.caracteristicas_especiales}
            </div>
            <div className='video-caracterizacion-producto'>
            <iframe  className="videos-rack" src={producto.video}  title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
        </section>

        
        
        
        </>
    )
}