
import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";

import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import axios from "axios";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper/modules";

import "../../style/home/home.css";
 
/* imagenes */
import Lineas from "../../img/home/contactohome/lineas.png";

export default function ConoceMaquinarias() {
  const [nosotros, setNosotros] = useState([]);
  const [currentVideo, setCurrentVideo] = useState({});
  const backendURL = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    // Realiza la solicitud a la API al cargar el componente
    axios.get(`${backendURL}/api/get/all/videos`)
      .then(response => {
        // Filtra los servicios que tienen tipo 'nosotros' en su array
        const nosotrosVideos = response.data.filter(video => video.tipo === 'nosotros');
        setNosotros(nosotrosVideos);
        setCurrentVideo(nosotrosVideos[0]); // Establece el primer video como el actual por defecto
      })
      .catch(error => {
        console.error('Error fetching servicios:', error);
      });
  }, []);

  const handleVideoClick = (video) => {
    setCurrentVideo(video);
  };

  return (
    <>
      <section className="titulo-nosotros-home" id="conoce-maquinarias">
        <img src={Lineas} alt="stacker" title="stacker" />
        <h1 className="titulo-home-nosotros">CONOCE MÁS DE NOSOTROS</h1>
      </section>
      <Grid key={currentVideo.id} container className="contenedorvideos-home">
        <Grid xs={12}>
          <div className="imagen-servicio-catalogo-video">
            <iframe
              className="video-home-servicios-iframe"
              width="100%"
              height="300%"
              src={currentVideo.url}
              title="YouTube video player"
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </div>
        </Grid>
        <Grid className="contenedor-slider-video-servicio" xs={12}>
        <Swiper
          slidesPerView={4}
          spaceBetween={1}
          breakpoints={{
            240: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            500: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            600: {
              slidesPerView: 1,
              spaceBetween: 1,
            },
            640: {
              slidesPerView: 2,
              spaceBetween: 1,
            },
            900: { slidesPerView: 2, spaceBetween: 1 },
            1565: {
              slidesPerView: 3,
              spaceBetween: 2,
            },
            1566: {
              slidesPerView: 4,
              spaceBetween: 2,
            },
          }}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[ Navigation]}
          className="video-home-servicios"
        >    
         {nosotros.map(video => (
          <SwiperSlide key={video.id}>
            <div className="imagen-video-home">
              <h1 className="titulo-video-home">{video.titulo}</h1>
              {video.imagen && (
                <img
                className="imagen-video-final-home"
                  src={`${backendURL}/${video.imagen}`}
                  alt={video.alt_seo || video.titulo}
                  
                />
              )}
              <button
                className="button-video-home"
                onClick={() => handleVideoClick(video)}
              >
                <svg
                  viewBox="0 0 448 512"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  width="26px"
                >
                  <path
                    d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z"
                    fill="currentColor"
                  ></path>
                </svg>
              </button>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </Grid>
  </Grid>
</>
);
}